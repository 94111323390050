<template>
  <Projects/>
</template>


<script>
export default {
  components: {
    Projects: () => import("../../src/components/projects/Projects"),
  },
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Projects", path:``, isActive: true}]
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>
